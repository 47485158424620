//import React from 'react';
//import { HighchartsReact } from 'highcharts-react-official';
//import { Chart, HighchartsChart, HighchartsProvider, Legend, LineSeries, Subtitle, Title, XAxis, YAxis } from 'react-jsx-highcharts';

import ChartModuleMore from 'highcharts/highcharts-more.js';
import HCData from 'highcharts/modules/data.js';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import * as Highcharts from 'highcharts';
import { Chart, SeriesGaugeOptions } from 'highcharts';

if (typeof Highcharts === 'object') {
    ChartModuleMore(Highcharts);
    HCData(Highcharts);
    HCSoldGauge(Highcharts);
}


async function getData(serviceUrl) {
    const response2 = await fetch(serviceUrl);
    const gridCondition = await response2.json();
    return gridCondition;
}

export function GridConditionsWidget(blockID: string, serviceUrl: string) {
    getData(serviceUrl).then(response => {
        drawChart(blockID, serviceUrl, response.condition,response.alertString);
    }).catch(error => {
        drawChart(blockID, serviceUrl, "error");
    })

}


function drawChart(blockID, serviceUrl, response, alertString = "") {
    let seriesGaugeOptions: SeriesGaugeOptions = {
        type: "gauge",
        enableMouseTracking: false,
        dial: {
            radius: '80%',
            backgroundColor: '#636569',
            baseWidth: 38,
            borderWidth: 3,
            borderColor: '#FFF',
            baseLength: '0%',
            rearLength: '0%',
            topWidth: 0,
        },
        showInLegend: false,
        data: [
            chartLookup[response]
        ],
        pivot: {
            radius: 17, borderWidth: 3, borderColor: "FFF", backgroundColor: "#636569"
        }
    };

    let seriesGaugeOptionsMobile: SeriesGaugeOptions = {
        type: "gauge",
        showInLegend: false,
        enableMouseTracking: false,
        dial: {
            radius: '80%',
            backgroundColor: '#636569',
            baseWidth: 28,
            borderWidth: 3,
            borderColor: '#FFF',
            baseLength: '0%',
            rearLength: '0%',
            topWidth: 0,
        },
        pivot: {
            radius: 12, borderWidth: 3, borderColor: "FFF", backgroundColor: "#636569"
        },
    };
    let seriesOptionsLabelYellow: SeriesGaugeOptions = {
        type: "gauge", name: "Advisories/Warnings", color: "#FFC844", events: { legendItemClick: function () { return false; } }, showInLegend: true, pivot: { radius: 4, borderWidth: 3, borderColor: "FFF", backgroundColor: "#FFF" }
    };
    let seriesOptionsLabelOrange: SeriesGaugeOptions = { type: "gauge", name: "Some risk that demand could exceed supply", color: "#ee7623", events: { legendItemClick: function () { return false; } }, showInLegend: true , pivot: { radius: 4, borderWidth: 3, borderColor: "FFF", backgroundColor: "#FFF" } };
    let seriesOptionsLabelRed: SeriesGaugeOptions = { type: "gauge", name: "Emergency power and/or reduced demand necessary", color: "#E74F3D", events: { legendItemClick: function () { return false; } }, showInLegend: true, pivot: { radius: 4, borderWidth: 3, borderColor: "FFF", backgroundColor: "#FFF" } };
    let seriesOptionsLabelDarkRed: SeriesGaugeOptions = { type: "gauge", name: "Power interruptions imminent or happening", color: "#B2292E", events: { legendItemClick: function () { return false; } }, showInLegend: true, pivot: { radius: 1, borderWidth: 4, borderColor: "FFF", backgroundColor: "#FFF" } };
    let gridChart = Highcharts.chart(blockID, {

        title: {
            text: chartLookup[response].text,
            style: {
                color: chartLookup[response].color,

            },
            //color:"",
            align: 'center',
            verticalAlign: 'bottom',
            useHTML: true,
            y: -30,
            //floating: true,
            //margin:10
            //x:-50
        },
        
        subtitle: {
            text: chartLookup[response].subtext + "<h5>" + alertString +"</h5>",
            useHTML: true,
            verticalAlign: 'middle',
            style: {
                color: "#000",
                textTransform: "uppercase",
                textAlign: "center",
                fontWeight:"bold"
            },
            y: 150,
        },
        yAxis: {
            min: 0, max: 100,
            plotBands: [{
                from: 0,
                to: 20,
                outerRadius: "99%",
                color: '#7fbc42',
                thickness: 60,
                className: "gauge1 insetShadowSVG",
            }, {
                from: 20,
                to: 40,
                outerRadius: "99%",
                color: '#FFC844',
                thickness: 60,
                className: "gauge2 insetShadowSVG"
            }, {
                from: 40,
                to: 60,
                outerRadius: "99%",
                color: '#ee7623',
                thickness: 60,
                className: "gauge3 insetShadowSVG"
            }, {
                from: 60,
                to: 80,
                outerRadius: "99%",
                color: '#E74F3D',
                thickness: 60,
                className: "gauge4 insetShadowSVG"
            }, {
                from: 80,
                to: 100,
                outerRadius: "99%",
                color: '#B2292E',
                thickness: 60,
                className: "gauge5 insetShadowSVG"
            }],
            tickLength: 64,
            tickWidth: 3,
            tickPositions: [0, 20, 40, 60, 80, 100],
            tickColor: "#fff",
            minorTickInterval: null,
            labels: { enabled: false },
            lineColor: "transparent",
            lineWidth: 0,
        },
        xAxis: {
            accessibility: { rangeDescription: '' }
        },
        legend: {
            enabled: alertString == "" && response == "green" ? true : false,
            //enabled: true,
            floating:true,
            layout: 'horizontal',
            verticalAlign: 'bottom',
            itemHoverStyle: {
                cursor: "default"
            },
            itemStyle: {
                display: "flex",
                textAlign: "left",
                fontSize: "14px",
                fontWeight:"normal"

            },
            useHTML: true,
            className: 'w-100 d-flex justify-content-between',
            squareSymbol:true,
            symbolHeight: 12,
            symbolWidth: 12,
            symbolRadius: 6,
            symbolPadding: 0,
            y: 35,

        },
        plotOptions: {
            series: {
                label: { enabled: false },
                point: {
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    }
                },

            },

        },
        pane: {
            startAngle: -90,
            endAngle: 89.9,
            background: null,
            center: ['50%', '75%'],
            size: '125%'
        },
        chart: {
            backgroundColor: 'transparent',
            height: "75%",
            marginBottom: 60,
            spacingTop: 0,
            spacingLeft: 0,
            spacingRight: 0,
            spacingBottom:30,
            
            reflow: true,
            style: {
                fontFamily: 'Lato, Arial, Helvetica, sans-serif',
                whiteSpace: "wrap"
            },
            events: {
                load: function () {
                    setTimeout(this.reflow.bind(this), 30);
                    setInterval(function () {
                        $.getJSON(serviceUrl,
                            function (data) {
                                response = data.condition;
                                alertString = data.alertString;
                                gridChart.series[0].setData([chartLookup[response]],false);
                                gridChart.title.update({
                                    text: chartLookup[response].text,
                                    style: {
                                        color: chartLookup[response].color,

                                    },
                                }, false);
                                gridChart.subtitle.update({
                                    text: chartLookup[response].subtext + "<h5>" + alertString + "</h5>",
                                }, false);
                                gridChart.legend.update({
                                    enabled: alertString == "" && response == "green" ? true : false
                                }, false);
                                gridChart.redraw();

                            }
                        )
                    }, 300000);
                },
                render: function () {
                    if (response == "green" && alertString.length == 0) {
                        $('#' + blockID.id + "additionalLink").html("Needle moves when alerts/warnings/events are active").css("display", "block").css("text-align", "center");
                    } else {
                        $('#' + blockID.id + "additionalLink").css("display", "block");
                    }
                }
            }
        },
        //series: [seriesGaugeOptions],
        series: [seriesGaugeOptions, seriesOptionsLabelYellow, seriesOptionsLabelOrange, seriesOptionsLabelRed, seriesOptionsLabelDarkRed],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 380
                },

                chartOptions: {
                    series: [seriesGaugeOptionsMobile, seriesOptionsLabelYellow, seriesOptionsLabelOrange, seriesOptionsLabelRed, seriesOptionsLabelDarkRed],
                    chart: { height: 330, spacingBottom: 30, marginBottom:10 },
                    pane: {
                        startAngle: -90,
                        endAngle: 89.9,
                        background: null,
                        center: ['50%', '60%'],
                        size: '100%'
                    }, title: { y: -20 }, subtitle: { y: 60 }, legend: {y:35},
                    yAxis: {
                        min: 0, max: 100,
                        plotBands: [{
                            from: 0,
                            to: 20,
                            borderWidth: 0,
                            borderColor: '#FFF',
                            outerRadius: "99%",
                            color: '#7fbc42',
                            thickness: 40,
                            className: "gauge1 insetShadowSVG"
                        }, {
                            from: 20,
                            to: 40,
                            borderWidth: 0,
                            borderColor: '#FFF',
                            outerRadius: "99%",
                            color: '#FFC844',
                            thickness: 40,
                            className: "gauge2 insetShadowSVG"
                        }, {
                            from: 40,
                            to: 60,
                            borderWidth: 0,
                            borderColor: '#FFF',
                            outerRadius: "99%",
                            color: '#ee7623',
                            thickness: 40,
                            className: "gauge3 insetShadowSVG"
                        }, {
                            from: 60,
                            to: 80,
                            borderWidth: 0,
                            borderColor: '#FFF',
                            outerRadius: "99%",
                            color: '#E74F3D',
                            thickness: 40,
                            className: "gauge4 insetShadowSVG"
                        }, {
                            from: 80,
                            to: 100,
                            borderWidth: 0,
                            borderColor: '#FFF',
                            outerRadius: "99%",
                            color: '#B2292E',
                            thickness: 40,
                            className: "gauge5 insetShadowSVG"
                        }],
                        tickLength: 64,
                        tickWidth: 3,
                        tickColor: "#fff",
                        minorTickInterval: null,
                        labels: { enabled: false },
                        lineColor: "transparent",
                        lineWidth: 0,
                    }
                },

            }, {
                condition: {
                    maxWidth: 300
                },
                chartOptions: {
                    
                    chart: { height: 330, spacingBottom: 30, marginBottom: 10 },
                    title: { y: -20 }, subtitle: { y: 60 }, legend: { y: 35 }
                }
            }]
        }

    });
}

const chartLookup = {
    "null": {
        y: 0,
        dataLabels: {
            enabled: false
        },
        text: "<div class='text-center margin-top-10'><h4>" + "Loading" + "</h4><h5>" + "" + '</h5></div>',
        subtext: "&nbsp",
        color: "#000"
    },
    "green": {
        y: 10,
        dataLabels: {
            enabled: false
        },
        text: "<h4 class='text-center margin-top-0 margin-btm-0'>Grid Conditions Normal</h4>",
        //subtext: "<h5>" + alertString +"</h5>",
        subtext: "",
        color: "#000"
    },
    "yellow": {
        y: 30,
        dataLabels: {
            enabled: false
        },
        text: "<h4 class='text-center margin-top-0'>Grid Advisories/Warnings</h4>",
        subtext: "<h5 class='text-center'>Grid Stable</h5>",
        color: "#000"
    },
    "orange": {
        y: 50,
        dataLabels: {
            enabled: false
        },
        text: "<h4 class='text-center margin-top-0'>Energy Emergency Alert 1 (EEA1)</h4>",
        subtext: "<h5 class='text-center'>Power demand could exceed available supply<br />Grid Stable</h5>",
        color: "#ee7623"
    },
    "lightRed": {
        y: 70,
        dataLabels: {
            enabled: false
        },
        text: "<h4 class='text-center margin-top-0'>Energy Emergency Alert 2 (EEA2)</h4>",
        subtext: "<h5 class='text-center'>Emergency power and/or reduced demand neccessary</h5>",
        color: "#E74F3D"
    },
    "darkRed": {
        y: 90,
        dataLabels: {
            enabled: false
        },
        text: "<h4 class='text-center margin-top-0'>Energy Emergency Alert 3 (EEA3)</h4>",
        subtext: "<h5 class='text-center'>Power interruptions imminent or happening</h5>",
        color: "#B2292E"
    },
    "error": {
        y: 0,
        dataLabels: {
            enabled: false
        },
        text: "<div class='text-center margin-top-10'><h4>" + "Site Timeout" + "</h4><h5>" + "Please refresh the page for further updates" + '</h5></div>',
        subtext: "",
        color: "#000"
    },
};