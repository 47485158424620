//import React from 'react';
//import { HighchartsReact } from 'highcharts-react-official';
//import { Chart, HighchartsChart, HighchartsProvider, Legend, LineSeries, Subtitle, Title, XAxis, YAxis } from 'react-jsx-highcharts';

import ChartModuleMore from 'highcharts/highcharts-more.js';
import HCSoldGauge from 'highcharts/modules/sunburst';
import HCData from 'highcharts/modules/data.js';
import * as Highcharts from 'highcharts';
import { Chart, SeriesPieOptions, PointOptionsObject } from 'highcharts';

if (typeof Highcharts === 'object') {
    ChartModuleMore(Highcharts);
    HCData(Highcharts);
    HCSoldGauge(Highcharts);
}
let totalMW = '';
async function getData(serviceUrl) {
    const response = await fetch(serviceUrl);
    const mix = await response.json();
    totalMW = parseInt(mix.TotalMW).toLocaleString("en-US");
    return format(mix.Fuel.Type);
}
function format(mix) {
    var formmattedMix = mix.map((fuel, i) => {
        return { name: fuel.CATEGORY, y: parseInt(fuel.ACT), color: ColorLookup[fuel.CATEGORY].color, className: "insetShadowSVG" }
    })
    return formmattedMix;
}
export function FuelMixWidget(blockID: string, serviceUrl:string) {

    getData(serviceUrl).then(response => {
        drawChart(blockID, serviceUrl, response);
    }).catch(error => {
        drawChart(blockID, serviceUrl, "error");
    })



}
function drawChart(blockID, serviceUrl, response) {
    let seriesPieOptionsDesktop: SeriesPieOptions = {
        innerSize: "80%",
        description: "fuelmix",
        dataLabels: [{
            format: ("<div class='text-center margin-top-10'><h4>" + "fuelmx" + "</h4><h5>" + "megawatt!" + '</h5></div>'),
            borderWidth: 0,
            useHTML: true,
            style: {
                padding: '20px 0px 0px 0px',
                fontSize: '24px',
                top: '30px',
                color: "333",
                textOutline: null
            }
        }],
        data: response,
        type: 'pie'
    }
    // Build the chart
    let pieChart = Highcharts.chart(blockID, {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            backgroundColor: 'transparent',
            //marginRight:140,
            //marginBottom: 90,
            height: (function (s) {
                //console.log(s);
                var gridRowHgt = 50;
                var cats = s.data.length;
                var newHgtpx = (cats) * (gridRowHgt) + 120;
                return newHgtpx;
            })(seriesPieOptionsDesktop),    
            style: {
                fontFamily: 'Lato, Arial, Helvetica, sans-serif',
                whiteSpace:"wrap"
                },
            events: {
                load: function () {
                    //setTimeout( () => console.log(this), 30);
                    setTimeout(this.reflow.bind(this), 30);
                    setInterval(function () {

                        $.getJSON(serviceUrl,
                            function (data) {
                                response = format(data.Fuel.Type);
                                totalMW = parseInt(data.TotalMW).toLocaleString("en-US");
                                pieChart.series[0].setData(response);
                                pieChart.setTitle({ text: '<b class="fs18">' + totalMW + ' MW</b><br><span class="fs14">Total Megawatts</span>' });
                                pieChart.legend.update(response, true);
                                //console.log(pieChart);
                                pieChart.redraw();
                            }
                        )
                    }, 300000);
                }
            }
        },
        title: {
            text: '<b class="fs18">' + totalMW + ' MW</b><br><span class="fs14">Total Megawatts</span>',
            align: 'center',
            verticalAlign: 'middle',
            y:-35,
            //floating: true,
            //margin:0
            //x:-50
        },
        tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>'
        },
        legend: {
            enabled: true,
            //align: "right",
            //itemWidth: 300,
            width: '100%',
            //floating:true,
            //useHTML:true,
            //x: 0,
            //y: -7,
            layout: 'horizontal',
            itemMarginBottom: 5,
            itemStyle: {
                display: "flex"
                //justify- content:"space-between"
                },
            className:'w-100 d-flex justify-content-between',
            labelFormatter: function () {
                return '<div class="fs14 w-100 d-flex justify-content-between margin-btm-5"><div class="bold">' + this.name + " </div> <div class='fw-normal'> " + this['y'].toLocaleString("en-US") + " MW" + '</div></div>';
            }
        },
        lang: {
            thousandsSep: ','
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 411
                },

                chartOptions: {
                    title: {
                        y: -55,
                        //floating: true,
                        //margin:0
                        //x:-50
                    },
                }
            }]
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                showInLegend: true,
                //center: ["50%",125]
            }
        },
        pane: {
            center: ['50%', '50%'],
            size: '80%'
        },
        series: [seriesPieOptionsDesktop]
        //name: 'Share',}
    });
}

let ColorLookup = {
    "Coal": { color: '#636569' },
    "Natural Gas": { color: '#0082CA' },
    "Nuclear": { color: '#EE7623' },
    "Wind": { color: '#80BC00' },
    "Solar": { color: '#FFC844' },
    "Other": { color: '#A7A8AA' },
    "Imports": { color: '#4B858E' }
};