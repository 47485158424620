import ChartModuleMore from 'highcharts/highcharts-more.js';
import HCSoldGauge from 'highcharts/modules/sunburst';
import HCData from 'highcharts/modules/data.js';
import * as Highcharts from 'highcharts';

if (typeof Highcharts === 'object') {
    ChartModuleMore(Highcharts);
    HCData(Highcharts);
    HCSoldGauge(Highcharts);
}

type CsatDataPoint = {
    id: Number,
    timeEst: Date,
    demand?: Number,
    committedCapacity?: Number,
    demandForecast?: Number,
    committedCapacityForecast?: Number,
    availableCapacity?: Number
}

var demandSeries: any[] = [] ;
var committedCapSeries: any[] = [];
var demandForecastSeries: any[] = [];
var committedCapForecastSeries: any[] = [];
var availableCapSeries: any[] = [];
var timeCutover;
const millisIn4Hrs = 14400000;
const millisIn5Hrs = 18000000;

var darkGreen = "#487629";
var mediumGreen = "#7fbc42";
var lightGreen = "#a5d07a";
var veryLightGreen = "#e5f2d9";
var blue = "#0082CA";
var lightBlue = "#3db5e6";

var sd_chart;

let serviceUrl = "";

async function getData() {
    const response = await fetch(serviceUrl);
    const data = await response.json();
    return format(data as CsatDataPoint[]);
}

function getDstOffset() {
    const stdTimezoneOffset = () => {
        var jan = new Date(0, 1)
        var jul = new Date(6, 1)
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
    }

    var today = new Date()
    
    const isDstObserved = (today: Date) => {
        return today.getTimezoneOffset() < stdTimezoneOffset()
    }

    if (isDstObserved(today)) {
        return millisIn4Hrs;
    } else {
        return millisIn5Hrs;
    }
}

function formatTime(dt: Date) : number{
    return new Date(dt).getTime() - getDstOffset();
}

function format(data: CsatDataPoint[]) {
    demandSeries = [] ;
    committedCapSeries = [];
    demandForecastSeries = [];
    committedCapForecastSeries = [];
    availableCapSeries = [];

    var prevPoint: CsatDataPoint;
    var prevTime: number;
    var time: number;

    data.forEach((point, i) => {
        if (isPointValid(prevPoint, point)) {
            time = formatTime(point.timeEst);
            demandSeries.push({x: time, y: point.demand, tooltip: true});
            committedCapSeries.push({x: time, y: point.committedCapacity, tooltip: true});
            demandForecastSeries.push({x: time, y: point.demandForecast, tooltip: true});
            committedCapForecastSeries.push({x: time, y: point.committedCapacityForecast, tooltip: true});
            availableCapSeries.push({x: time, y: point.availableCapacity, tooltip: true});

            if(point.demandForecast != null && prevPoint.demandForecast == null){
                timeCutover = prevTime;
                demandForecastSeries[i-1] = {x: prevTime, y: prevPoint.demand, tooltip: false};
                committedCapForecastSeries[i-1] = {x: prevTime, y: prevPoint.committedCapacity, tooltip: false};
                availableCapSeries[i-1] = {x: prevTime, y: prevPoint.committedCapacity, tooltip: false};
            }

            prevPoint = point;
            prevTime = time;
        }
    });
}

function isPointValid(prevPoint: CsatDataPoint, point: CsatDataPoint) : boolean  {
    if(prevPoint == null) return true;
    return (
           (prevPoint.demand == null || point.demand == null || (Math.abs((prevPoint.demand as number) - (point.demand as number)) < 30000))
        && (prevPoint.committedCapacity == null || point.committedCapacity == null || (Math.abs((prevPoint.committedCapacity as number) - (point.committedCapacity as number)) < 30000))
        && (prevPoint.demandForecast == null || point.demandForecast == null || (Math.abs((prevPoint.demandForecast as number) - (point.demandForecast as number)) < 30000))
        && (prevPoint.committedCapacityForecast == null || point.committedCapacityForecast == null || (Math.abs((prevPoint.committedCapacityForecast as number) - (point.committedCapacityForecast as number)) < 30000))
        && (prevPoint.availableCapacity == null || point.availableCapacity == null || (Math.abs((prevPoint.availableCapacity as number) - (point.availableCapacity as number)) < 30000))
    );
}

export function CsatSupplyDemandWidget(blockID: string, serviceUrlString: string) {
    serviceUrl = serviceUrlString;
    getData().then(response => {
        drawChart(blockID);
    }).catch(error => {
        drawChart(blockID); // Error stuff
    })
}

function refreshChart() {
    getData().then(() => {
        sd_chart.redraw();
        setTimeout(refreshChart, 300000);
    });
}

function drawChart(blockID) {
    Highcharts.setOptions({
        lang: {
          thousandsSep: ','
      }
    })
    sd_chart = Highcharts.chart(blockID, {
        title: {
            text:'',
            align: 'center'
        },
        chart: {
            height:400,
            reflow: true,
            style: {
                fontFamily: 'Lato, Arial, Helvetica, sans-serif',
                whiteSpace: "wrap"
            },
            events: {
                load: function () {
                    setTimeout(this.reflow.bind(this), 30);
                    setTimeout(refreshChart, 1000);
                },
            }
        },
        plotOptions: {
            series: {
                // general options for all series
            },
            spline: {
                // shared options for all spline series
                marker: {
                    enabled: false
                },
                lineWidth: 3
            },
            areaspline: {
                // shared options for all line series
                marker: {
                    enabled: false
                },
                lineWidth: 3,
                opacity: .75
            }
        },
        series: [
            // specific options for the series instance
            {
                name: "Committed Capacity",
                description: "Committed Capacity",
                type: 'spline',
                data: committedCapSeries,
                color: darkGreen, // dark green
            },
            {
                name: "Demand",
                description: "Demand",
                type: 'spline',
                data: demandSeries,
                color: blue,
            },
            {
                name: "Available Capacity",
                description: "Available Capacity",
                type: 'spline',
                data: [],
                dashStyle: 'ShortDash',
                color: mediumGreen, // medium green
                id: "Available Capacity",
            },
            {
                name: "Available Capacity",
                description: "Available Capacity",
                type: 'areaspline',
                data: availableCapSeries,
                dashStyle: 'ShortDash',
                color: mediumGreen, // medium green
                fillColor: {    
                    linearGradient: { 
                        x1: 0, x2: 0, y1: 0, y2: 1 
                    },    
                    stops: [        
                        [0, veryLightGreen],  // very light green  
                        [1, 'white']    
                    ]
                },
                linkedTo: "Available Capacity",
            },
            {
                name: "Committed Capacity Forecast",
                description: "Committed Capacity Forecast",
                type: 'areaspline',
                data: committedCapForecastSeries,
                dashStyle: 'Dot',
                color: darkGreen, // dark green
                showInLegend: false,
                fillColor: {    
                    linearGradient: { 
                        x1: 0, x2: 0, y1: 0, y2: 1 
                    },    
                    stops: [        
                        [0, veryLightGreen],  // very light green  
                        [1, 'white']    
                    ]
                },
                linkedTo: "Committed Capacity Forecast",
            },
            {
                name: "Committed Capacity Forecast",
                description: "Committed Capacity Forecast",
                type: 'spline',
                data: [],
                dashStyle: 'Dot',
                color: darkGreen, // dark green
                id: "Committed Capacity Forecast",
            },
            {
                name: "Demand Forecast",
                description: "Demand Forecast",
                type: 'areaspline',
                data: demandForecastSeries,
                dashStyle: 'ShortDash',
                color: blue,
                showInLegend: false,
                fillColor: {    
                    linearGradient: { 
                        x1: 0, x2: 0, y1: 0, y2: 1 
                    },    
                    stops: [        
                        [0, lightBlue],        
                        [1, 'white']    
                    ]
                },
                linkedTo: "Demand Forecast",
            },
            {
                name: "Demand Forecast",
                description: "Demand Forecast",
                type: 'spline',
                data: [],
                dashStyle: 'ShortDash',
                color: blue,
                id: "Demand Forecast",
            },
        ],
        legend: {
            enabled: true,
            width: '100%',
            layout: 'horizontal',
            itemMarginBottom: 5,
            itemStyle: {
                display: "flex"
                },
            className:'w-100 d-flex justify-content-between',
            labelFormatter: function () {
                return '<div class="fs14 w-100 d-flex justify-content-between margin-btm-5"><div class="bold">' + this.name + " </div> <div class='fw-normal'> </div></div>";
            }
        },
        yAxis: {
            title:{
                text: null
            },
            softMax: 120000,
            softMin: 30000,
        },
        xAxis: {
            title: {
                text: '<strong>Hours since midnight EST ' + new Date().toLocaleDateString('en-us', { month:"short", day:"numeric" }) +'</strong>'
            },
            type: 'datetime',
            dateTimeLabelFormats : {
                hour: '%k',
            },
            labels: {
                format: '{value:%k}'
            },
            plotLines: [{
                color: '#C0C0C0', // Grey
                width: 4,
                value: timeCutover,
                zIndex: 3
            }]
        },
        tooltip: {
            shared: true,
            formatter: function(){
                if(this.points == null) return false;
                return this.points.reduce(formatTooltip, '<b>' + formatTooltipTime(this.x) + '</b>'); // points.reduce(format func(prev_val, val), initial_prev_val)
            }
        },
    });
}

function formatTooltipTime(dtNum){
    var dt = new Date(dtNum + getDstOffset());
    var time = `${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')} EST`;
    return time;
}

function formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); // comma separated value
  }

// s = previous value (i.e. contents of current tool tip) OR initial value
// point = point obj for current series's entry (y value) at this x
function formatTooltip(s: string, point: Highcharts.TooltipFormatterContextObject): string{
    // if this point is to be excluded, return current tooltip
    if(!point.point["tooltip"]) return s;

    // keep the existing tooltip, add break line
    var tooltip = s + '<br />';
    
    // now add to it
    tooltip += '<span style="color:'+ point.color +'">\u25cf</span> '+ point.series.name +': <b>' + formatNumber(point.y) +' MW</b>';
    return tooltip;
}